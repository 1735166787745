import axios from '../../../axios/index.js';
import store from '../../../../store/store.js';
import router from '@/router';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

let bus = new Vue();

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function setCookie(cname, cvalue, exdays) {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function onAccessTokenFetched(refreshData) {
  localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`, refreshData.accessToken);
  localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`, refreshData.user.firstName);
  localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`, JSON.stringify(refreshData.user));
  setCookie('token', refreshData.accessToken, 1);
  Vue.$cookies.set(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`,
    refreshData.refreshToken,
    refreshData.refreshTokenExpiresIn,
    '',
    window.location.host,
    true,
  );
  localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`, 'true');
  store.commit('UPDATE_USER_INFO', refreshData.user, { root: true });
  store.commit('SET_BEARER', refreshData.accessToken);
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  init() {
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`);
        if (token) {
          config.headers.Authorization = token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error && error.message && error.message === 'Network Error') {
          return Promise.reject({
            data: { message: "Network Error. Couldn't connect to server." },
          });
        }

        const {
          config,
          response: { status },
        } = error;

        if (config.url.includes('login') && status === 401) {
          return Promise.reject(error);
        }

        if (config.url.includes('refreshToken') && status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          router.push('/login');
        }

        if (config.url.includes('refreshToken') && status === 422) {
          localStorage.clear();
          sessionStorage.clear();
          router.push('/login');
        }

        const originalRequest = config;

        // if (status === 401) {
        if (status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            store
              .dispatch('auth/fetchAccessToken')
              .then((refreshTokenResponse) => {
                isAlreadyFetchingAccessToken = false;
                onAccessTokenFetched(refreshTokenResponse.data.data);
                originalRequest.headers.Authorization = 'Bearer ' + refreshTokenResponse.data.data.accessToken;
                bus.$vs.loading.close();
                return Promise.resolve(axios(originalRequest));
              })
              .catch((err) => {
                bus.$vs.loading.close();
                return Promise.reject(axios(originalRequest));
              });
          }
        }

        if (status === 403 && error.response.data === 'Permission Denied') {
          console.log('#############################');
          router.push('/admin/permission-denied');
        }

        return Promise.reject(error.response);
      },
    );
  },
  login(email, pwd, rememberMe, userId = null, secret = null) {
    let url = `/api/v1/admins/auth/login`;
    if (userId) {
      url = `/api/v1/admins/auth/login?userId=${userId}&secret=${secret}`;
    }
    return axios.post(url, {
      email: email,
      password: pwd,
      rememberMe: rememberMe,
    });
  },
  fetchUserDetail() {
    let path = '/api/v1/myDetails';
    const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (clinic) {
      path = path + '?clinicId=' + clinic;
    }
    return axios.get(path);
  },
  fetchCardDetail() {
    return axios.get('/api/v1/cardDetails');
  },
  fetchCardListApi() {
    return axios.get('/api/v1/clinics/credit-cards');
  },
  fetchAccountPaymentDetailsListApi(data) {
    return axios.get('api/v1/clinics/payment-methods/' + data.id + '?' + data.idType + '=' + data.id);
  },
  fetchClinicPaymentDetailsApi(id, orgId = null) {
    if (!orgId) {
      return axios.get(`api/v1/clinics/payment-methods/${id}?clinicId=${id}`);
    } else {
      return axios.get(`api/v1/clinics/payment-methods/${orgId}`);
    }
  },
  removeCardDetailApi() {
    return axios.post('/api/v1/removeCardDetails');
  },
  saveCardDetailApi(data) {
    return axios.post('/api/v1/cardDetails', data);
  },
  fetchClinicCardDetailForAdmin(data) {
    return axios.post('/api/v1/cardDetailsForAdmin', data);
  },
  registerUser(name, email, pwd, userType = 'admin') {
    return axios.post('/api/v1/register', {
      displayName: name,
      email: email,
      password: pwd,
      userType: userType,
    });
  },
  registerFranchise(userDetails) {
    return axios.post('/api/v1/franchises/register', userDetails);
  },
  registerDoctorClinic(userDetails) {
    return axios.post('/api/v1/franchises/register-doctor-clinic', userDetails);
  },
  registerClinic(userDetails) {
    return axios.post('/api/v1/clinics/register', userDetails);
  },
  checkEmailAvailability(email) {
    return axios.post('/api/v1/franchises/check-email-availability', { email });
  },
  refreshToken() {
    return axios.post('/api/v1/refreshToken', {
      HasCredRemembered: Vue.$cookies.get(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}HasCredRemembered`),
      refreshToken: Vue.$cookies.get(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`),
    });
  },
  checkTokenExpiry(token) {
    return axios.post('/api/v1/checkTokenExpiry', { token });
  },
  resetPassword(password, token) {
    return axios.post('/api/v1/resetPassword', {
      password: password,
      token: token,
    });
  },
  forgotPasswordAdmin(email) {
    return axios.post('/api/v1/forgotPassword', { email: email });
  },
  changePassword(currentPassword, newPassword) {
    return axios.post('/api/v1/changePassword', {
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
  },
  fetchHttpProduct(data) {
    return axios.get('/api/v1/admins/products', { params: data });
  },
  fetchBrands(data) {
    return axios.get('/api/v1/admins/brands');
  },
  fetchHttpPopularBrands() {
    return axios.get(
      '/api/v1/admins/brands/popular',
    );
  },
  fetchHttpApplicableAreas(data) {
    return axios.get('/api/v1/admins/products/applicable-areas', {
      params: data,
    });
  },
  fetchAllHttpApplicableAreas(data) {
    return axios.get('/api/v1/admins/products/all-applicable-areas', {
      params: data,
    });
  },
  saveHttpProduct(data) {
    return axios.post('/api/v1/admins/products/create', data);
  },
  saveHttpApplicableArea(data) {
    return axios.post('/api/v1/admins/applicable-areas/create', data);
  },
  updateHttpProductDetail(data) {
    return axios.post('/api/v1/admins/products/update/' + data.id, data.info);
  },
  updateHttpApplicableArea(data) {
    return axios.post('/api/v1/admins/applicable-areas/update/' + data.id, data.info);
  },
  deleteHttpProduct(id) {
    return axios.delete('/api/v1/admins/products/delete/' + id);
  },
  deleteHttpApplicableArea(id) {
    return axios.delete('/api/v1/admins/applicable-areas/delete/' + id);
  },
  fetchHttpProductDetail(id) {
    return axios.get('/api/v1/admins/products/fetchDetail/' + id);
  },
  fetchHttpApplicableAreaDetail(id) {
    return axios.get('/api/v1/admins/applicable-areas/fetchDetail/' + id);
  },
  fetchAllProducts() {
    return axios.get('/api/v1/store/products/fetchAllProducts');
  },
  //category
  saveHttpCategory(data) {
    return axios.post('/api/v1/admins/category/create', data);
  },
  fetchHttpParentCategoryList() {
    return axios.get('/api/v1/admins/category/list');
  },
  fetchHttpCategoryList(data) {
    return axios.get(
      '/api/v1/admins/category/all/list?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        data.search +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir,
    );
  },
  fetchHttpPopularCategory() {
    return axios.get(
      '/api/v1/admins/category/popular',
    );
  },
  fetchHttpSubCategoryList(id) {
    return axios.get('/api/v1/admins/category/' + id + '/subCategory/list ');
  },
  fetchHttpCategoryDetails(id) {
    return axios.get('/api/v1/admins/category/details/' + id);
  },
  fetchHttpCategoryDetailsBySlug(slug) {
    return axios.get('/api/v1/admins/category/details/slug/' + slug);
  },
  updateHttpCategoryDetail(data) {
    return axios.post('/api/v1/admins/category/update/' + data.id, data.info);
  },
  fetchHttpProductListByCategory(data, id) {
    return axios.get(
      '/api/v1/admins/category/' +
        id +
        '/product/list?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        data.search +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir,
    );
  },
  deleteHttpCategory(id) {
    return axios.delete('/api/v1/admins/category/delete/' + id);
  },
  deleteHttpProductFromCategory(data) {
    return axios.delete('/api/v1/admins/category/' + data.categoryId + '/product/' + data.productId + '/remove');
  },
  //discount
  fetchHttpDiscountList(data) {
    return axios.get(
      '/api/v1/admins/discounts/list?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        encodeURIComponent(data.search) +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir,
    );
  },
  fetchAllHttpDiscount() {
    return axios.get('/api/v1/admins/discounts/list/all');
  },
  saveHttpDiscount(data) {
    return axios.post('/api/v1/admins/discounts/create', data);
  },
  fetchHttpDiscount(id) {
    return axios.get('/api/v1/admins/discounts/' + id);
  },
  updateHttpDiscountDetail(data) {
    return axios.post('/api/v1/admins/discounts/update/' + data.id, data.info);
  },
  deleteHttpDiscount(id) {
    return axios.delete('/api/v1/admins/discounts/delete/' + id);
  },

  //web store suppliers
  saveHttpSupplier(data) {
    return axios.post('/api/v1/store/suppliers/create', data);
  },
  fetchHttpAllSupplierList() {
    return axios.get('/api/v1/store/suppliers/getAllSuppliers');
  },
  fetchHttpAllInstantSupplierList() {
    return axios.get('/api/v1/store/suppliers/instant');
  },
  fetchHttpSupplierList(data) {
    return axios.get(
      '/api/v1/store/suppliers/list?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        encodeURIComponent(data.search) +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir,
    );
  },
  fetchHttpSupplierDetail(id) {
    return axios.get('/api/v1/store/suppliers/details/' + id);
  },
  fetchHttpsearchNearbyClinics(data) {
    return axios.get(
      '/api/v1/store/suppliers/searchNearbyClinics?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        data.search +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir +
        '&radiusLimit=' +
        data.radiusLimit +
        '&supplierId=' +
        data.supplierId,
    );
  },
  updateHttpSupplierDetail(data) {
    return axios.put('/api/v1/store/suppliers/update/' + data.id, data.info);
  },
  fetchHttpProductListBySupplier(data, id) {
    return axios.get(
      '/api/v1/store/suppliers/' +
        id +
        '/product/list?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        data.search +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir,
    );
  },
  deleteHttpProductFromSupplier(data) {
    return axios.delete('/api/v1/store/suppliers/' + data.supplierId + '/product/' + data.productId + '/remove');
  },
  fetchHttpOrderListBySupplier(data, id) {
    return axios.get(
      '/api/v1/store/suppliers/' +
        id +
        '/order/list?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        data.search +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir,
    );
  },
  fetchHttpOrderDetailForSupplier(data) {
    return axios.get('/api/v1/store/suppliers/' + data.supplierId + '/order/details/' + data.orderId);
  },
  //web store orders
  fetchHttpOrderList(data) {
    return axios.get(
      '/api/v1/store/orders/list?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        data.search +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir,
    );
  },
  fetchHttpOrderDetail(id) {
    return axios.get('/api/v1/store/orders/details/' + id);
  },
  resendEmail({orderId, supplierId}) {
    return axios.post(`/api/v1/store/orders/details/${orderId}/resend-invoice/${supplierId}`);
  },
  resendCustomerEmail(orderId) {
    return axios.post(`/api/v1/store/orders/details/${orderId}/resend-invoice`);
  },
  fetchHttpOrderDetailIntent(id) {
    return axios.get('/api/v1/store/orders/intent/' + id);
  },
  updateHttpOrderTracking(orderNumber, body) {
    return axios.post('/api/v1/store/orders/' + orderNumber + '/updateTracking', body);
  },
  updateHttpOrderTrackingInfo(orderNumber, body) {
    return axios.post('/api/v1/store/orders/' + orderNumber + '/updateTrackingInfo', body);
  },
  fetchHttpProductForOrder(data) {
    return axios.get(
      '/api/v1/store/orders/product/list?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        data.search +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir +
        '&clinicId=' +
        data.clinicId,
    );
  },
  createOrderByAdminHttp(data) {
    return axios.post('/api/v1/store/orders/create', data);
  },
  //store product
  updateProductHttpHideStatus(data) {
    return axios.put('/api/v1/store/products/' + data.id + '/updateHideStatus', data.data);
  },
  //ecomemrce
  fetchProductFilter(data) {
    let path = '/api/v1/clinics/getProductFilters';
    const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (clinic) {
      if (data) {
        data.clinicId = clinic;
      } else {
        data = {
          clinicId: clinic,
        };
      }
    }
    return axios.get(path, {
      params: data,
    });
  },
  addProductStoreInCart(item) {
    let path = '/api/v1/clinics/addProductStoreInCart';
    const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (clinic) {
      path = path + '?clinicId=' + clinic;
    }
    return axios.post(path, item);
  },
  updateProductStoreCartFromCheckout(item) {
    let path = '/api/v1/clinics/updateProductStoreCartFromCheckout';
    const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (clinic) {
      path = path + '?clinicId=' + clinic;
    }

    return axios.post(path, item);
  },
  paymentForCart(data) {
    let path = '/api/v1/clinics/checkoutPayment';
    const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (clinic) {
      path = path + '?clinicId=' + clinic;
    }
    return axios.post(path, data);
  },
  retryPayment(data) {
    let path = '/api/v1/clinics/retryPayment';
    if (sessionStorage.getItem('doctorClinicSelectedClinic')) {
      path = `/api/v1/clinics/retryPayment?clinicId=${sessionStorage.getItem('doctorClinicSelectedClinic')}`;
    }
    return axios.post(path, data);
  },
  hasFailedPayments(id) {
    return axios.get(`/api/v1/clinics/has-failed-payments?id=${id}`);
  },
  fetchOrderedProductList(data) {
    let path =
      '/api/v1/clinics/orders?page=' +
      data.page +
      '&limit=' +
      data.limit +
      '&search=' +
      data.search +
      '&sort=' +
      data.sort +
      '&dir=' +
      data.dir;
    const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (data.clinicId) {
      path = path + '&clinicId=' + data.clinicId;
    } else if (clinic) {
      path = path + '&clinicId=' + clinic;
    }
    return axios.get(path);
  },
  fetchOrderDetailApi(orderId, clinicId) {
    return axios.get('/api/v1/clinics/orders/' + orderId + `?clinicId=${clinicId}`);
  },
  updateOrderStatusApi(orderId, data) {
    return axios.post('/api/v1/clinics/orders/updateStatus/' + orderId, data);
  },
  orderPaymentApi(orderId, data) {
    return axios.post('/api/v1/clinics/orders/payment/' + orderId, data);
  },
  sendInvoiceToClinicApi(id) {
    return axios.get('/api/v1/store/orders/' + id + '/sendInvoiceToClinic');
  },
  sendTrackingToClinicApi(id) {
    return axios.get('/api/v1/store/orders/' + id + '/sendTrackingToClinic');
  },
  fetchSupplierMinimumOrder() {
    return axios.get('/api/v1/clinics/minimum-suppliers');
  },
  fetchMinimumOrderRequirement() {
    return axios.get('/api/v1/clinics/supplier-requirement');
  },
  createMinimumOrder(data) {
    return axios.post('/api/v1/clinics/minimum-order', data);
  },
  fetchCartItems(id) {
    let path = '/api/v1/clinics/getCartProductStore';
    let clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (clinic || id) {
      if (id) {
        clinic = id;
      }
      path = path + '?clinicId=' + clinic;
    }
    return axios.get(path);
  },
  fetchProductListing(data) {
    let path = '/api/v1/clinics/products';
    const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (clinic) {
      if (data) {
        data.clinicId = clinic;
      } else {
        data = {
          clinicId: clinic,
        };
      }
    }
    return axios.get(path, {
      params: data,
    });
  },
  removeProductStore(productStoreId) {
    let path = '/api/v1/clinics/products/' + productStoreId + '/delete';
    const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (clinic) {
      path = path + '?clinicId=' + clinic;
    }
    return axios.delete(path);
  },
  fetchEcommerceProductDetail(id) {
    let path = '/api/v1/clinics/products/' + id;
    const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    if (clinic) {
      path = path + '?clinicId=' + clinic;
    }
    return axios.get(path);
  },
  getNotificationBadgeCount() {
    return axios.get(`/api/v1/notifications/getCmsNotificationsBatchCount`);
  },
  httpFetchAdminClinicUpcomingInvoice(data) {
    return axios.get(
      '/api/v1/admins/upcoming-invoice/list?page=' +
        data.page +
        '&limit=' +
        data.limit +
        '&search=' +
        data.search +
        '&sort=' +
        data.sort +
        '&dir=' +
        data.dir,
    );
  },
  httpFetchAdminClinicInvoiceDetails(data) {
    return axios.get(
      '/api/v1/admins/upcoming-invoice/details/' +
        data.invoiceId +
        '?page=' +
        data.param.page +
        '&limit=' +
        data.param.limit +
        '&search=' +
        data.param.search,
    );
  },
  httpSaveUpcomingInvoiceTreatmentDetail(data) {
    return axios.post('/api/v1/admins/upcoming-invoice/details/save-treatment', data);
  },
  httpSaveUpdatedTreatmentDetail(data) {
    return axios.post('/api/v1/admins/upcoming-invoice/details/save-updated-treatment', data);
  },
  httpSendUpdatedTreatmentPdf(invoiceId) {
    return axios.post('/api/v1/admins/upcoming-invoice/details/send-updated-treatment', { invoiceId });
  },
  removeUserBankDetails(payload) {
    return axios.post('/api/v1/clinics/remove-payment-method', {
      _method: 'patch',
      ...payload,
    });
  },
  removeCreditCardDetails(payload) {
    return axios.post('/api/v1/removeCreditCardDetails', {
      _method: 'patch',
      ...payload,
    });
  },
  setDefaultPaymentMethod(payload) {
    return axios.post('/api/v1/clinics/set-default-payment-method', {
      _method: 'patch',
      ...payload,
    });
  },
  httpVoidEntirePaymentForClinic(data) {
    return axios.post('/api/v1/admins/upcoming-invoice/void-entire-clinic-payment', data);
  },
  httpExportClinicInvoiceCsv(data) {
    return axios.post('/api/v1/admins/invoice/export-clinic-invoice-csv', data);
  },
  httpExportDoctorInvoiceCsv(data) {
    return axios.post('/api/v1/admins/invoice/export-doctor-invoice-csv', data);
  },
  regeneratePDF(id) {
    return axios.get('/api/v1/generate-invoice/' + id);
  },
  regenerateOrderPDF(id) {
    return axios.get(`/api/v1/clinics/regenerate-invoice-pdf?orderId=${id}`);
  },
  addNewPaymentMethod(data) {
    return axios.post('/api/v1/clinics/add-payment-method', data);
  },
  sendEnquiry(data) {
    return axios.post('/api/v1/clinics/products/enquiry', data);
  },
};
